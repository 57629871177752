import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import {
  documentToReactComponents,
  HEADING_1,
  HEADING_2,
  HEADING_3,
  HEADING_4,
  HEADING_5,
  HEADING_6,
  PARAGRAPH,
} from "@contentful/rich-text-react-renderer"

import { generatePath } from "./../utils/helpers"
import ImageOverlay from "./Shared/ImageOverlay"

const StyledText = styled(motion.div)`
  width: 100%;

  text-align: ${props => props.alignment};

  h1,
  h2,
  h3 {
    margin-bottom: 1rem;

    color: currentColor;
  }

  h2 {
    a {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  p {
    b {
      font-family: ${props => props.theme.fontFamily.proximaBold};
      color: ${props => props.theme.colors.white};
    }
  }

  blockquote {
    p {
      font-family: ${props => props.theme.fontFamily.estiRegular};
      font-size: 3rem;
      line-height: 4rem;

      color: ${props => props.theme.colors.white};
    }
  }

  ul {
    margin-bottom: 1rem;

    list-style: initial;

    li {
      margin-left: 2rem;

      p {
        margin-bottom: 0;
      }
    }
  }

  ${props => props.theme.above.desktop`
        h1, h2, h3 {
            margin-bottom: 3rem;
        }

        h2 {
            a {
                font-size: 6.6rem;
                line-height: 6rem;
            }
        }

        h4, h5, h6 {
            margin-bottom: 2rem;
        }

        p {
            &:not(:last-of-type) {
                margin-bottom: 2rem;
            }
        }

        ul {
            margin-bottom: 2rem;
        }

        blockquote {
            p {
                font-size: 6rem;
                line-height: 7rem;
            }
        }
    `}
`

const ImageWrapper = styled.div`
  position: relative;

  margin-bottom: 2rem;

  ${props => props.theme.above.desktop`
        margin-bottom: 3rem;
    `}
`

const StyledSvg = styled.img`
  position: relative;

  width: 100%;
  height: 100%;
`

const StyledImage = styled.img`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
`

const OverlayWrapper = styled(motion.div)`
  position: absolute;

  top: 0;
  left: 0;

  transform-origin: right;

  width: 100%;
  height: 100%;

  background-color: ${props =>
    props.backgroundColor || props.theme.colors.white};
`

const LinkStyles = css`
  display: inline-block;
  position: relative;

  color: currentColor;

  text-decoration: none;

  transition: color 0.35s ${props => props.theme.transitions.cubic};

  ${props => props.theme.underline("currentColor", 0.1)}
`

const LinkExternal = styled.a`
  ${LinkStyles}
`

const LinkInternal = styled(Link)`
  ${LinkStyles}
`

const getHyperlink = (node, children, lang) => {
  if (node.data.uri.includes("mailto") || node.data.uri.includes("tel")) {
    return (
      <LinkExternal label={children[0]} href={node.data.uri}>
        {children}
      </LinkExternal>
    )
  } else if (node.data.uri.includes("http")) {
    return (
      <LinkExternal label={children[0]} href={node.data.uri} target="_blank">
        {children}
      </LinkExternal>
    )
  } else {
    return (
      <LinkInternal label={children[0]} to={generatePath(lang, node.data.uri)}>
        {children}
      </LinkInternal>
    )
  }
}

const TextRenderer = ({
  data,
  lang,
  animate,
  alignment,
  className,
  backgroundColor,
}) => {
  return (
    <StyledText
      className={`text-block ${className || ""}`}
      alignment={alignment || "left"}
      animate={animate || "after"}
      initial={{
        opacity: 0,
      }}
      variants={{
        before: {
          opacity: 0,
          y: 120,
        },
        after: {
          opacity: 1,
          y: 0,
        },
      }}
      transition={{
        delay: 0.35,
        duration: 0.6,
        damping: 100,
        ease: "circOut",
      }}
    >
      {data &&
        documentToReactComponents(data.json, {
          renderNode: {
            [HEADING_1]: (node, children) => <h1>{children}</h1>,
            [HEADING_2]: (node, children) => <h2>{children}</h2>,
            [HEADING_3]: (node, children) => <h3>{children}</h3>,
            [HEADING_4]: (node, children) => <h4>{children}</h4>,
            [HEADING_5]: (node, children) => <h5>{children}</h5>,
            [HEADING_6]: (node, children) => <h6>{children}</h6>,
            [PARAGRAPH]: (node, children) => <p>{children}</p>,
            [INLINES.HYPERLINK]: (node, children) =>
              getHyperlink(node, children, lang),
            [INLINES.ASSET_HYPERLINK]: (node, children) => {
              const { file } = node.data.target.fields
              return (
                <LinkExternal
                  label={children[0]}
                  href={file["en-US"].url}
                  target="_blank"
                >
                  {children[0]}
                </LinkExternal>
              )
            },
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
              const { title, description, file } = node.data.target.fields
              const assetType = file["en-US"].contentType.split("/")[0]

              const imageType =
                assetType == "image" && file["en-US"].contentType.split("/")[1]
              const dimensions =
                assetType == "image" ? file["en-US"].details.image : null

              if (imageType == "svg+xml") {
                return (
                  <ImageWrapper
                    style={{
                      width: dimensions.width,
                      height: dimensions.height,
                    }}
                  >
                    <StyledSvg
                      className="svg"
                      title={title ? title["en-US"] : "AES International"}
                      alt={
                        description ? description["en-US"] : "AES International"
                      }
                      src={file["en-US"].url}
                    />
                  </ImageWrapper>
                )
              } else {
                return (
                  <ImageWrapper
                    style={{
                      paddingTop: `${(
                        (dimensions.height / dimensions.width) *
                        100
                      ).toFixed()}%`,
                    }}
                  >
                    <StyledImage
                      className="image"
                      title={title ? title["en-US"] : "AES International"}
                      alt={
                        description ? description["en-US"] : "AES International"
                      }
                      src={file["en-US"].url}
                    />
                  </ImageWrapper>
                )
              }
            },
          },
        })}
    </StyledText>
  )
}

export default TextRenderer
