import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import { mapStyles } from './../styles/mapStyles.js'
import theme from '../styles/theme.js'

const Wrapper = styled.div`
    position: relative;

    width: 100%;
    height: 100vh;

    max-height: 30rem;

    overflow: hidden;

    > div {
        height: 110% !important;
    }

    ${props => props.theme.above.desktop`
        max-height: 60rem;

        > div {
            height: 105% !important;
        }
    `}
`

const Background = styled.div`
    display: none;

    position: absolute;
    
    top: 0;
    left: 0;

    transform: translate(-50%, -50%);

    width: 100vw;
    height: 100vh;

    background: linear-gradient(to bottom, rgba(23, 21, 21, 0.1) 0%, rgba(23, 21, 21, 0.6) 50%, rgba(23, 21, 21, 1) 70%);

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const Location = styled.div`
    position: relative;

    width: 4rem;
    height: 4rem;

    margin-top: -2rem;

    &:after {
        content: '';
        position: absolute;
        
        bottom: 0;
        left: 50%;

        transform: translate(-50%, 100%);

        width: 0.2rem;
        height: 2rem;

        background-color: ${props => props.theme.colors.white};
    }

    ${props => props.theme.above.desktop`
        width: 6rem;
        height: 6rem;
    `}
`

const Circle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 4rem;
    height: 4rem;

    background-color: ${props => props.theme.colors.white};

    border-radius: 100%;

    ${props => props.theme.above.desktop`
        width: 6rem;
        height: 6rem;
    `}
`

const Image = styled.img`
    width: 3rem;
    height: 3rem;

    ${props => props.theme.above.desktop`
        width: 4.5rem;
        height: 4.5rem;
    `}
`

const Map = ({
    className,
    pin
}) => {
    
    const params = {
        location: {
            lat: 52.315922,
            lng: 4.855870
        },
        center: {
            lat: 52.304834,
            lng: 4.895541
        },
        zoom: 13
    }

    const center = typeof window != 'undefined' && window.innerWidth > theme.breakpoints.desktop ? params.center : params.location

    return (
        <Wrapper className={className}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyAHZzMCh37WxFatFJVCUEl6gYZ3VqFipxo' }}
                defaultCenter={center}
                defaultZoom={params.zoom}
                options={{
                    styles: mapStyles,
                    disableDefaultUI: true,
                    fullscreenControl: false,
                    disableDoubleClickZoom: true,
                    zoomControl: false
                }}
            >
                <Background/>
                <Location
                    lat={52.315922}
                    lng={4.855870}
                >
                    <Circle>
                        <Image src={pin.file.url} alt='Pin' />
                    </Circle>
                </Location>
            </GoogleMapReact>
        </Wrapper>
    )
}

export default Map
