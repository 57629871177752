import { css, keyframes } from 'styled-components'

const mobileVW = px => {
    return `${(px / 375) * 100}vw`
}

const desktopVW = px => {
    return `${(px / 1440) * 100}vw`
}

const breakpoints = {
    desktop: 1023,
    wide: 1440,
    extraWide: 2000
}

const colors = {
    white: '#fff',
    black: '#000',
    ocher: '#CCC4B4',
    greyDark: '#171515',
    greyLight: '#F5F5F5',
    greyText: '#888888'
}

const fontFamily = {
    proximaRegular: 'ProximaRegular',
    proximaBold: 'ProximaBold',
    estiLight: 'EstiLight',
    estiRegular: 'EstiRegular',
    estiBold: 'EstiBold'
}

const fontSize = {
    d1: desktopVW(70),
    d2: desktopVW(50),
    m1: mobileVW(70),
    m2: mobileVW(50)
}

const border = {
    light: '1px solid rgba(255, 255, 255, 0.15)'
}

const transitions = {
    cubic: 'cubic-bezier(.25,.46,.45,.94)'
}

const above = Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media screen and (min-width: ${breakpoints[label]}px) {
            ${css(...args)}
        }
    `
    return acc
}, {})

const below = Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media screen and (max-width: ${breakpoints[label]}px) {
            ${css(...args)}
        }
    `
    return acc
}, {})

const theme = {
    mobileVW,
    desktopVW,
    breakpoints,
    colors,
    fontFamily,
    fontSize,
    border,
    transitions,
    above,
    below,

    underline: (color, height) => css`
        &:after {
            content: '';
            display: block;

            transform: scaleX(0);
            transform-origin: right;

            height: ${height}rem;

            margin-top: -0.1rem;
            
            background-color: ${color};
            
            transition: transform 0.35s cubic-bezier(.25,.46,.45,.94);
        }

        &:hover {
            :after {
                transform: scaleX(1);
                transition: transform 0.35s cubic-bezier(.25,.46,.45,.94);
                transform-origin: left;
            }
        }
    `
}

export default theme