import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Map from './../Map'
import Grid from './../Grid'
import TextRenderer from '../TextRenderer'

const StyledFooter = styled.footer`
    padding: 0;

    background-color: ${props => props.theme.colors.greyDark};
    
    color: ${props => props.theme.colors.white};

    ${props => props.theme.below.desktop`
        .grid-col {
            margin-bottom: 2rem;
        }
    `}
`

const StyledMap = styled(Map)`
    margin-bottom: 4rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 10rem;
    `}
`

const Top = styled(Grid)`
    ${props => props.theme.below.desktop`
        margin-bottom: 0;
        padding: 0 4rem 2rem 4rem;
    `}

    ${props => props.theme.above.desktop`
        margin-bottom: 10rem;
    `}
`

const StyledGrid = styled(Grid)`
    ${props => props.theme.below.desktop`
        text-align: center;
    `}   
`

const Quote = styled.h3`
    display: block;

    width: 100%;

    font-family: ${props => props.theme.fontFamily.estiRegular};

    color: currentColor;

    ${props => props.theme.above.desktop`
        max-width: 40rem;

        margin-bottom: 2rem;

        font-size: 5rem;
        line-height: 5.5rem;
    `}
`

const Socials = styled.div`
    display: block;
`

const ExternalLink = styled.a`
    position: relative;
    display: inline-block;

    font-family: ${props => props.theme.fontFamily.proximaRegular};
    font-size: 1.8rem;
    line-height: 3.2rem;

    color: ${props => props.theme.colors.greyText};

    transition: color 0.35s ${props => props.theme.transitions.cubic};

    &:not(:last-of-type) {
        margin-right: 2.5rem;
    }
    
    ${props => props.theme.underline('currentColor', 0.1)}

    &:hover {
        color: ${props => props.theme.colors.white};
    }
`

const StyledTextRenderer = styled(TextRenderer)`
    text-align: center;

    h4 {
        font-family: ${props => props.theme.fontFamily.estiRegular};

        color: currentColor;
    }

    p {
        text-align: left;
        
        &:not(:last-of-type) {
            margin-bottom: 0;
        }
    }

    a {
        color: ${props => props.theme.colors.white};
    }

    ${props => props.theme.above.desktop`
        text-align: left;

        h4 {
            margin-bottom: 1rem;

            font-size: 2.8rem;
            line-height: 2.8rem;
        }

        p {
            font-size: 1.8rem;
            line-height: 3.2rem;

            &:not(:last-of-type) {
                margin-bottom: 0;
            }
        }
    `}
`

const Bottom = styled(Grid)`
    font-family: ${props => props.theme.fontFamily.proximaRegular};
    font-size: 1.3rem;
    line-height: 2rem;

    &:before {
        content: '';
        position: absolute;
        
        top: 0;
        left: 0;
        
        width: 100%;
        height: 0.1rem;

        background-color: rgba(255, 255, 255, 0.15);
    }

    ${props => props.theme.below.desktop`
        padding: 2rem 0;

        .grid-col {
            margin-bottom: 0;

            text-align: center;
        }
    `}

    ${props => props.theme.above.desktop`
        padding-top: 5rem;
        padding-bottom: 5rem;
    `}
`

const Copyright = styled.span`
    display: inline-block;

    color: ${props => props.theme.colors.greyText};
`

const Language = styled.span`
    display: none;

    ${props => props.theme.above.desktop`
        display: block;

        text-align: right;
    `}
`

const InternalLink = styled(Link)``

const Footer = ({
    lang, 
    contentTheme
}) => {
    return (
        <StyledFooter>
            <StyledMap pin={contentTheme.pin} />
            <Top type='row'>
                <StyledGrid type='column' columns={6} offset={0}>
                    <Quote>{contentTheme.quote}</Quote>
                    <Socials>
                        {contentTheme.facebook && <ExternalLink href={contentTheme.facebook} target='_blank'>Facebook</ExternalLink>}
                        {contentTheme.linkedIn && <ExternalLink href={contentTheme.linkedIn} target='_blank'>LinkedIn</ExternalLink>}
                    </Socials>
                </StyledGrid>
                <Grid type='column' columns={3} offset={0}>
                    <StyledTextRenderer lang={lang} data={contentTheme.address} />
                </Grid>
                <Grid type='column' columns={3} offset={0}>
                    <StyledTextRenderer lang={lang} data={contentTheme.contact} />
                </Grid>
            </Top>
            <Bottom type='row'>
                <Grid type='column' columns={10} offset={0}>
                    <Copyright>&copy; {new Date().getFullYear()} AES International. All Rights Reserved</Copyright>
                </Grid>
                <Grid type='column' columns={2} offset={0}>
                    {/* <Language>
                        <InternalLink to={lang == 'nl-NL' ? '/en' : '/nl'}>{lang == 'nl-NL' ? 'English' : 'Nederlands'}</InternalLink>
                    </Language> */}
                </Grid>
            </Bottom>
        </StyledFooter>
    )
}

export default Footer