import React, { useRef, useEffect, useState } from 'react'
import { motion, useCycle, AnimatePresence } from 'framer-motion'
import GlobalStyle from './GlobalStyle'

import Menu from './Menu'
import Header from './Header'
import Footer from './Footer'

const duration = 0.5
const variants = {
	initial: {
		opacity: 0,
		y: -25,
		transition: {
			damping: 100,
			ease: 'circOut'
		}
	},
	enter: {
		opacity: 1,
		y: 0,
		transition: {
			duration: duration,
			delay: duration,
			when: 'beforeChildren',
			damping: 100,
			ease: 'circOut'
		}
	},
	exit: {
		opacity: 0,
		transition: { 
			duration: duration,
			damping: 100,
			ease: 'circOut'
		}
	}
}

const Layout = ({ 
	children,
	location,
	lang,
	contentTheme
}) => {
	
	const [menuOpen, toggleMenuOpen] = useCycle(false, true)
	const [isSticky, setSticky] = useState(true)
	
	const handleScroll = () => {
		setSticky(window.scrollY <= 0)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', () => handleScroll)
		}
	}, [])

  	return (
		<>
			<GlobalStyle
				shouldDisableScroll={menuOpen ? true : false}
			/>
			<Header
				lang={lang}
				contentTheme={contentTheme}
				menuOpen={menuOpen}
				toggleMenuOpen={() => toggleMenuOpen()}
				isSticky={isSticky}
			/>
			<Menu 
				lang={lang}
				contentTheme={contentTheme}
				menuOpen={menuOpen}
				toggleMenuOpen={() => toggleMenuOpen()}
			/>
			<AnimatePresence>
				<motion.main
					key={location.pathname}
					variants={variants}
					initial='initial'
					animate='enter'
					exit='exit'
				>
					{children}
				</motion.main>
			</AnimatePresence>
			<Footer
				lang={lang}
				contentTheme={contentTheme}
			/>
		</>
	)
}

export default Layout
