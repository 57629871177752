export const generatePath = (lang, slug) => `${lang.substring(0, 2)}/${slug ? `${slug}/` : ''}`
export const padLeft = (number) => number.toString().padStart(2, '0');
export const hook = (method, payload = {}) => { if (method && typeof method === 'function') method.call(null, payload) }

export const getColor = (theme, color) => {
    switch(color) {
        case 'Transparent': return 'transparent'
        case 'Ocher' : return theme.colors['ocher']
        case 'GreyLight' : return theme.colors['greyLight']
        case 'GreyDark' : return theme.colors['greyDark']
        case 'White' : return theme.colors['white']
    }
}