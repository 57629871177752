import React from 'react'
import { motion } from 'framer-motion'
import theme from './../styles/theme'

const Path = props => {
    return (
        <motion.path
            fill='transparent'
            strokeWidth='2'
            stroke='#fff'
            {...props}
        />
    )
}

const MenuToggle = ({ 
    toggle, 
    className,
    menuOpen
}) => (
    <button className={className} onClick={toggle}>
        <svg width='23' height='23' viewBox='0 0 23 23'>
            <Path
                variants={{
                    closed: { 
                        d: 'M 2 2.5 L 20 2.5' ,
                        stroke: theme.colors.white
                    },
                    open: { 
                        d: 'M 3 16.5 L 17 2.5',
                        stroke: theme.colors.greyDark
                    }
                }}
            />
            <Path
                d='M 2 9.423 L 20 9.423'
                variants={{
                    closed: { opacity: 1 },
                    open: { opacity: 0 }
                }}
                transition={{ duration: 0.1 }}
            />
            <Path
                variants={{
                    closed: { 
                        d: 'M 2 16.346 L 20 16.346' ,
                        stroke: theme.colors.white
                    },
                    open: { 
                        d: 'M 3 2.5 L 17 16.346',
                        stroke: theme.colors.greyDark
                    }
                }}
            />
        </svg>
    </button>
)

export default MenuToggle