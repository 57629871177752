import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import ProximaRegular from './../../fonts/ProximaNova-Regular.woff'
import ProximaBold from './../../fonts/ProximaNova-Bold.woff'

import EstiLight from './../../fonts/GTEestiProDispTrialLight.woff'
import EstiRegular from './../../fonts/GTEestiProDispTrialRegular.woff'
import EstiBold from './../../fonts/GTEestiProDispTrialBold.woff'

const GlobalStyle = createGlobalStyle`
	${reset}

	@font-face {
        font-family: 'ProximaRegular';
		src: url('${ProximaRegular}') format('woff');
		font-style: normal;
	}

	@font-face {
        font-family: 'ProximaBold';
		src: url('${ProximaBold}') format('woff');
		font-style: normal;
	}

	@font-face {
        font-family: 'EstiLight';
		src: url('${EstiLight}') format('woff');
		font-style: normal;
	}

	@font-face {
        font-family: 'EstiRegular';
		src: url('${EstiRegular}') format('woff');
		font-style: normal;
	}

	@font-face {
        font-family: 'EstiBold';
		src: url('${EstiBold}') format('woff');
		font-style: normal;
	}

	html {
		font-size: 62.5%;

		${props => props.theme.above.desktop`
			font-size: 50%;
		`}

		${props => props.theme.above.wide`
			font-size: 65.2%;
		`}
	}

	body {
		background-color: ${props => props.theme.colors.greyDark};

		font-family: ${props => props.theme.fontFamily.proximaRegular}, 'Arial', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		overflow: ${props => (props.shouldDisableScroll ? 'hidden' : 'auto')};
		overflow-x: hidden;
        // perspective: 450px;
    }

	main {
		background-color: ${props => props.theme.colors.greyDark};
	}

    *, *:before, *:after {
        box-sizing: border-box;
    }

	*:focus {
		outline:none;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	button,
	input {
		border: 0;
		background: transparent;
		margin: 0;
		padding: 0;
		outline: 0;
		-webkit-font-smoothing: antialiased;
	}

	input,
	textarea,
	button,
	select,
	div,
	a {
		-webkit-tap-highlight-color: transparent;
	}

	h1 {
		font-family: ${props => props.theme.fontFamily.estiRegular};
		font-size: 4rem;
		line-height: 4rem;
	}

	h2 {
		font-family: ${props => props.theme.fontFamily.estiRegular};
		font-size: 3.5rem;
		line-height: 3.5rem;

		color: ${props => props.theme.colors.greyDark};
	}

	h3 {
		font-family: ${props => props.theme.fontFamily.estiRegular};
		font-size: 3rem;
		line-height: 3.5rem;
	}

	h4 {
		font-family: ${props => props.theme.fontFamily.estiLight};
		font-size: 2rem;
		line-height: 3rem;
	}

	h5 {
		font-family: ${props => props.theme.fontFamily.estiRegular};
		font-size: 1.7rem;
		line-height: 2.5rem;
	}

	p {
		font-family: ${props => props.theme.fontFamily.proximaRegular};
		font-size: 1.7rem;
		line-height: 3rem;

		color: ${props => props.theme.colors.greyText};
	}

	.mobile {
		display: block;
	}

	.desktop {
		display: none;
	}

	${props => props.theme.above.desktop`
		h1 {
			font-size: 8rem;
			line-height: 8rem;
		}

		h2 {
			font-size: 6.6rem;
			line-height: 6.3rem;
		}

		h3 {
			font-size: 4rem;
			line-height: 5rem;
		}

		h4 {
			font-size: 2.5rem;
			line-height: 4rem;
		}

		h5 {
			font-size: 2rem;
			line-height: 3rem;
		}

		p {
			font-size: 2rem;
			line-height: 3.4rem;
		}

		.mobile {
			display: none;
		}

		.desktop {
			display: block;
		}
	`}
`

export default GlobalStyle
