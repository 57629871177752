import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const OverlayWrapper = styled(motion.div)`
    position: absolute;
    
    top: 0;
    left: 0;

    z-index: 1;

    transform-origin: right;
    
    width: 100%;
    height: 100%;

    background-color: ${props => props.backgroundColor || props.theme.colors.white};
`

const ImageOverlay = ({
    inView,
    backgroundColor
}) => {
    return (
        <OverlayWrapper 
            animate={inView ? 'after' : 'before'}
            backgroundColor={backgroundColor}
            initial={{
                scaleX: 0
            }}
            variants={{
                before: {
                    scaleX: 1
                },
                after: {
                    scaleX: 0
                }
            }}
            transition={{
                duration: 1,
                damping: 100,
                ease: 'circOut'
            }}
        />
    )
}

export default ImageOverlay
