import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Navigation from './../Navigation'
import ButtonMenu from './../ButtonMenu'

import { generatePath } from './../../utils/helpers'

const StyledHeader = styled(motion.header)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    
    top: 0;
    left: 0;

    z-index: 4;
    
    width: 100%;

    padding: 1rem 1rem 0 2rem;

    color: ${props => props.theme.colors.white};

    transition: all 0.25s ${props => props.theme.transitions.cubic};

    ${props => props.theme.above.desktop`
        padding: 1rem 4rem;

        background-color: ${props => props.theme.colors.greyDark};

        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);

        ${props.sticky == 'true' && `
            padding: 4rem;

            background-color: transparent;

            box-shadow: none;
        `}
    `}
`

const StyledButtonMenu = styled(ButtonMenu)`
    width: 6rem;
    height: 6rem;

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const LogoWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const StyedLogo = styled.img`
    width: 7.5rem;
    height: 4rem;
    
    object-fit: contain;

    margin-right: 1rem;

    ${props => props.theme.above.desktop`
        width: 12rem;
        height: 5.5rem;

        margin-right: 2rem;
    `}
`

const SubLink = styled(Link)`
    display: none;

    font-family: ${props => props.theme.fontFamily.estiBold};
    font-size: 1rem;
    line-height: 1.4rem;
    letter-spacing: 0.5rem;

    text-transform: uppercase;

    opacity: 0.25;

    color: ${props => props.menuOpen == 'true' ? props.theme.colors.greyDark : props.theme.colors.white};

    transition: opacity 0.25s ${props => props.theme.transitions.cubic};

    span {
        display: block;
    }

    &:hover {
        opacity: 1;
    }

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const StyledNavigation = styled(Navigation)`
    display: none;

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const Header = ({
    lang,
    contentTheme,
    toggleMenuOpen,
    menuOpen,
    isSticky
}) => {
    return (
        <StyledHeader
            initial={false}
            animate={menuOpen ? 'open' : 'closed'}
            sticky={isSticky.toString()}
        >
            <LogoWrapper>
                <Link to={generatePath(lang, '')}>
                    <StyedLogo src={menuOpen ? contentTheme.logo[1].file.url : contentTheme.logo[0].file.url} alt='AES International Logo' />
                </Link>
                <SubLink to={generatePath(lang, 'ago-ergo-sum')}>
                    <span>Ago</span>
                    <span>Ergo</span>
                    <span>Sum</span>
                </SubLink>
            </LogoWrapper>
            <StyledNavigation 
                lang={lang}
                contentTheme={contentTheme}
            />
            <StyledButtonMenu 
                toggle={toggleMenuOpen}
                menuOpen={menuOpen}
            />
        </StyledHeader>
    )
}

export default Header
