import React from 'react'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'

import Navigation  from './../Navigation'

const StyledMenu = styled.aside`
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 3;

    width: 100%;
    height: 100vh;

    background-color: ${props => props.theme.colors.white};

    ${props => props.menuOpen == 'true' ? `
        display: block;
    ` : `
        display: none;
    `}
`

const StyledNavigation = styled(Navigation)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
`

const Menu = ({
    lang, 
    className,
    contentTheme,
    menuOpen,
    toggleMenuOpen
}) => {
    return (
        // <Div100vh>
            <StyledMenu 
                className={className} 
                menuOpen={menuOpen.toString()}
            >
                <Div100vh>
                    <StyledNavigation
                        lang={lang}
                        contentTheme={contentTheme}
                        toggleMenuOpen={toggleMenuOpen}
                    />
                </Div100vh>
            </StyledMenu>
        // </Div100vh>
    )
}

export default Menu
