import React from 'react';
import styled from 'styled-components';

const GRID_TYPE_ROW = 'row';
const GRID_TYPE_COLUMN = 'column';
const GRID_COLUMN_ALIGN_DEFAULT = 'left';

const config = {
    padding: 100,
    wrapper: 1240,
    columns: 12,
    gutter: 40,
    debug: `&:before, *:before { content: ''; top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 1px solid red; pointer-events: none; }`
}

const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    
    position: relative;
    
    width: 100%;
    max-width: ${props => (props.config.wrapper) + (props.config.padding * 6.0)}px;
    
    margin: 0 auto;
    padding: 0;
    
    ${props => props.debug && props.config.debug}

    ${props => props.theme.above.desktop`
        padding: 0 ${props => props.config.padding - (props.config.gutter * 2.0)}px;
    `}
`

const StyledCol = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    
    position: relative;
    
    width: 100%;
    
    margin: 0;
    padding: 0 ${props => props.config.gutter / 20}rem;
    
    text-align: ${props => props.align};

    ${props => props.theme.above.desktop`
        width: ${props => ((1 / props.config.columns) * props.columns) * 100}%;
        
        margin: 0 0 0 ${props => ((1 / props.config.columns) * props.offset) * 100}%;
        padding: 0 ${props => props.config.gutter}px;
    `}
`;

const Grid = ({ 
    type, 
    columns, 
    align, 
    offset, 
    debug, 
    children,
    className
}) => {
    switch (type) {
        case GRID_TYPE_ROW: return (<StyledRow className={`grid-row ${className || ''}`} config={ config } debug={ debug }>{ children }</StyledRow>);
        case GRID_TYPE_COLUMN: return (<StyledCol className={`grid-col ${className || ''}`} config={ config } columns={ columns } align={ align ? align : GRID_COLUMN_ALIGN_DEFAULT } offset={ offset ? offset : 0 }>{ children }</StyledCol>);
        default: return children;
    }
}

export default Grid;
